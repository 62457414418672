import React from 'react';

const ArrowLeft = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 30 30"><path d="M19.98 3.99a1 1 0 0 0-.687.303l-10 10a1 1 0 0 0 0 1.414l10 10a1 1 0 1 0 1.414-1.414L11.414 15l9.293-9.293a1 1 0 0 0-.727-1.717z" /></svg>;
const ArrowRight = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M9.99 3.99a1 1 0 0 0-.697 1.717L18.586 15l-9.293 9.293a1 1 0 1 0 1.414 1.414l10-10a1 1 0 0 0 0-1.414l-10-10a1 1 0 0 0-.717-.303z" /></svg>;
const ArrowDown = (props) => <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 129 129"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" /></svg>;
const ArrowUp = (props) => <svg {...props} style={{ transform: 'rotate(180deg)' }} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 129 129"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" /></svg>;
const Checkmark = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M6.61 11.89L3.5 8.78 2.44 9.84 6.61 14l8.95-8.95L14.5 4z" /></svg>;
const Clear = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M4.99 3.99a1 1 0 0 0-.697 1.717L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 1 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0-.727-1.717 1 1 0 0 0-.687.303L12 10.586 5.707 4.293a1 1 0 0 0-.717-.303z" /></svg>;
const Lambda = (props) =><svg  {...props}xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M9 5v2h2c.793 0 1.516.48 1.813 1.219l1.593 3.969L8 27h2.188l5.25-12.188 3.906 9.72A3.94 3.94 0 0 0 23 27h2v-2h-2a1.95 1.95 0 0 1-1.813-1.219L14.657 7.47A3.94 3.94 0 0 0 11 5z" /></svg>
const Nicotine = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M3 1a1 1 0 0 0-1 1 1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 .803-.406l.785.726A4.442 4.442 0 0 0 3 6.5c0 1.05.4 1.988 1 2.748V10c0 .086.03.163.05.242l-1.212 1.213A1 1 0 0 0 2 11a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-.455-.838l1.213-1.213c.08.021.156.051.242.051v.299C5 12.233 5.767 13 6.701 13H8.3C9.233 13 10 12.233 10 11.299V11c.086 0 .163-.03.242-.05l1.213 1.212A1 1 0 0 0 11 13a1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-.838.455l-1.213-1.213c.021-.08.051-.156.051-.242v-.752c.6-.76 1-1.699 1-2.748 0-.801-.226-1.545-.596-2.197l.758-.758A1 1 0 0 0 13 4a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 .455.838l-.643.642A4.474 4.474 0 0 0 7.5 2a4.473 4.473 0 0 0-3.326 1.498l-.682-.629A1 1 0 0 0 4 2a1 1 0 0 0-1-1zm4.5 2C9.439 3 11 4.561 11 6.5c0 .88-.335 1.671-.877 2.291l-.123.14V10H9v1.299a.693.693 0 0 1-.701.701H6.7A.693.693 0 0 1 6 11.299V10H5V8.932l-.123-.141A3.456 3.456 0 0 1 4 6.5C4 4.561 5.561 3 7.5 3zm-1 4a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5zm2 0a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5zM7 9l.5 1L8 9H7z" /></svg>;
const Search = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 30 30"><path d="M13 3C7.489 3 3 7.489 3 13s4.489 10 10 10c2.3965 0 4.5974-.851 6.3223-2.2637l5.9707 5.9707a1.0001 1.0001 0 1 0 1.414-1.414l-5.9707-5.9707C22.149 17.5973 23 15.3965 23 13c0-5.511-4.489-10-10-10zm0 2c4.4301 0 8 3.5699 8 8 0 4.4301-3.5699 8-8 8-4.4301 0-8-3.5699-8-8 0-4.4301 3.5699-8 8-8z" /></svg>;
const Sigma = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 3v3.459L10.463 12 4 17.541V21h15v-2H6v-.541L13.537 12 6 5.541V5h13V3H4z" /></svg>;
const Triangle = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.969 2a.501.501 0 0 0-.399.246l-6.5 11A.5.5 0 0 0 1.5 14h13a.5.5 0 0 0 .43-.754l-6.5-11A.5.5 0 0 0 8.016 2h-.047zM8 3.48L13.625 13H2.375z"/></svg>;
const Weight = (props) => <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M8 1c-1.098 0-2 .902-2 2 0 .367.11.703.281 1H3.5a.502.502 0 0 0-.488.39l-2 9A.502.502 0 0 0 1.5 14h13a.502.502 0 0 0 .488-.61l-2-9A.502.502 0 0 0 12.5 4H9.719c.172-.297.281-.633.281-1 0-1.098-.902-2-2-2zm0 1c.559 0 1 .441 1 1 0 .559-.441 1-1 1-.559 0-1-.441-1-1 0-.559.441-1 1-1zM3.898 5h8.2l1.777 8H2.125z"/></svg>;

const Icon = {
    ArrowLeft,
    ArrowRight,
    ArrowDown,
    ArrowUp,
    Checkmark,
    Clear,
    Lambda,
    Nicotine,
    Search,
    Sigma,
    Triangle,
    Weight,
}
export default Icon;
