/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import DosaPattern from '../images/dosa.svg';
import Header from './header';
import '../styles/tailwind.css';


const Layout = ({ isIndex, children }) => {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <div className="min-h-screen pb-16 relative" style={{ backgroundImage: `url(${DosaPattern})` }}>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <main className={`p-4 pb-5 m-auto ${!isIndex ? 'max-w-2xl' : ''}`}>
                        {children}
                    </main>
                    <footer className="text-sm text-center h-16 p-2 absolute bottom-0 w-full border-t">
                        © {new Date().getFullYear()}, Snuseriffic Empire,
                        Digitalbin Finity Inc.
                        <div className="mt-1">
                            <Link to="/kontakt" className="link">
                                Kontakt
                            </Link>
                        </div>
                    </footer>
                </div>
            )}
        />
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export const snusFragment = graphql`
    fragment snusFragment on Snus {
        brandName
        brand
        name
        type
        format
        weight
        nicotine
        strength
        image
        slug
        productImage {
            childImageSharp {
                fluid(maxWidth: 256, maxHeight: 256) {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }
        prices {
            price
            url
            reseller {
                resellerName
                shippingPrice
            }
        }
    }
`;

export default Layout;
