import React, { useEffect, useState, useRef } from 'react';
import * as JsSearch from 'js-search';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Icon from './icons';

const Search = ({ data }) => {
    const dataToSearch = new JsSearch.Search('brandName');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('brandName');
    const tokenizer = {
        tokenize(text) {
            return text
            .replace('é', 'e')
            .replace('.', '')
            .split(' ')
            .filter(
                (text) => text // Filter empty tokens
            );
        }
    }
    dataToSearch.tokenizer = tokenizer;
    dataToSearch.addIndex('brandName');
    dataToSearch.addDocuments(data);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [cursor, setCursor] = useState(-1);

    const formDOM = useRef(null);

    const searchData = () => {
        const queryResult = dataToSearch.search(searchQuery);
        setSearchResults(queryResult.slice(0, 8));
        setCursor(-1);
    }

    const handleSubmit = e => {
        e.preventDefault();
        window.location = `/search?q=${searchQuery}`;
    };

    const keyHandler = e => {
        if (e.key === 'Escape' || e.key === 'Tab') setIsOpen(false);
        else if (e.key === 'ArrowUp' && cursor >= 0) {
            e.preventDefault();
            setCursor(c => c - 1);
        } else if (e.key === 'ArrowDown' && cursor < searchResults.length - 1) {
            e.preventDefault();
            setCursor(c => c + 1);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (searchResults[cursor]) window.location = searchResults[cursor].slug;
            else handleSubmit(e);
        }
    };

    const clearSearch = e => {
        if (e) e.preventDefault();
        setSearchQuery('');
    };

    useEffect(() => {
        searchData();
        if (isOpen) formDOM.current.focus();
        else formDOM.current.blur();
    }, [searchQuery, isOpen]);

    const className = isOpen ? 'fixed z-50 inset-0 p-2 bg-white-opacity transition-ease' : 'sm:w-46';
    return (
        <div className={className}>
            <form
                onSubmit={handleSubmit}
                onFocus={() => setIsOpen(true)}
                role="presentation"
                onKeyDown={keyHandler}
                autoComplete="off"
                className={`sm:border-b border-black flex ${isOpen ? 'max-w-sm bg-white mt-4 mx-auto shadow-md rounded-t-lg' : ''}`}
            >
                {isOpen ? (
                    <button
                        className="w-12 h-12 flex items-center justify-center focus:outline-none"
                        aria-label="Close search"
                        onClick={() => setIsOpen(false)}
                    >
                        <Icon.ArrowLeft className="w-6" />
                    </button>
                ) : (
                    <button
                        className="w-12 h-12 flex items-center justify-center focus:outline-none border border-black rounded-full sm:border-none"
                        aria-label="Open search"
                        onClick={() => setIsOpen(true)}
                    >
                        <Icon.Search className="w-6" />
                    </button>
                )}
                <label
                    className={`flex ${isOpen ? 'flex-1' : ''}`}
                    htmlFor="search"
                >
                    <span className="sr-only">Sök</span>
                    <input
                        id="search"
                        type="search"
                        placeholder="Sök billig prilla"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onSubmit={handleSubmit}
                        ref={formDOM}
                        className={`${!isOpen ? 'hidden' : ''} sm:block border-none focus:outline-none p-2 flex-1 self-stretch w-32 overflow-hidden`}
                    />
                    {searchQuery.length > 0 && (
                        <button
                            className={`text-gray-700 mr-2 w-12 h-12 flex items-center justify-center focus:outline-none ${isOpen ? 'block' : 'hidden'}`}
                            aria-label="Clear search input"
                            onClick={clearSearch}
                        >
                            <Icon.Clear className="w-4" />
                        </button>
                    )}
                    {isOpen && (
                        <button
                            className="bg-black w-12 h-12 text-white rounded-tr-lg flex items-center justify-center"
                            type="submit"
                            aria-label="Search submit"
                            onClick={handleSubmit}
                        >
                            <Icon.Search className="w-6" />
                        </button>
                    )}
                </label>
            </form>
            <nav className={`max-w-sm mx-auto flex-col shadow-md bg-white rounded-b-lg overflow-hidden ${isOpen ? 'flex' : 'hidden'}`}>
                {searchResults.map((item, i) => {
                    const cname = `block p-4 border-l-2 ${cursor === i ? 'border-primary shadow' : ''}`;
                    return (
                        <Link
                            onMouseOver={() => setCursor(i)}
                            onFocus={() => setCursor(i)}
                            className={cname}
                            key={item.brandName}
                            to={item.slug}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Img
                                    fluid={item.productImage.childImageSharp.fluid}
                                    alt={item.brandName}
                                    style={{
                                        width: 50,
                                        minWidth: 50,
                                        height: 50,
                                        marginRight: '1rem',
                                    }}
                                />
                                <span>{item.brandName}</span>
                            </div>
                        </Link>
                    );
                })}
            </nav>
            {isOpen && (
                <div
                    className="absolute inset-0 -z-1"
                    role="presentation"
                    onClick={() => setIsOpen(false)}
                />
            )}
        </div>
    );
}

export default Search;
