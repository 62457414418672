import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Search from './search';
import Logos from './logos';
// import './header.scss';

// const themes = [
//     { val: 'default', text: 'Billig Prilla Original' },
//     { val: 'general', text: 'General Original' },
//     { val: 'generalwhite', text: 'General White' },
//     { val: 'goteborgs', text: 'Göteborgs Rapé' },
//     { val: 'ettan', text: 'Ettan' },
// ];

const Header = () => {
    // const [theme, setTheme] = useState('default');
    return (
      <StaticQuery
        query={graphql`
            query {
                allSnus {
                    nodes {
                        brandName
                        slug
                        productImage {
                            childImageSharp {
                                fluid(maxWidth: 250, maxHeight: 250) {
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ allSnus }) => (
            <>
                <header className="bg-white z-50 shadow sticky top-0 sm:relative pr-2 sm:pr-8">
                    <div className="flex justify-between items-center">
                        <Link to="/" title="Hem" tabIndex="0" className="focus:outline-none">
                            <Default />
                            {/* <Goteborgs />
                            <General />
                            <Ettan /> */}
                        </Link>
                        <Search data={allSnus.nodes} />
                    </div>
                </header>
                {/* <div className="choose-theme">
                    {themes.map(t => (
                        <button key={t.val} onClick={() => setTheme(t.val)}>{t.text}</button>
                    ))}
                </div> */}
            </>
        )}
        />
    );
};

const Default = () => (
    <div className="flex items-center justify-center text-white bg-black w-16 h-16 sm:w-24 sm:h-24">
      <Logos.BP className="h-10 sm:h-16" />
      {/* <Logos.BPs className="block sm:hidden h-12" /> */}
    </div>
);
// const Goteborgs = () => (
//     <div className="billig-prilla goteborgs">
//         <h2>
//             Billig <br />
//             Prilla.sé
//         </h2>
//     </div>
// );
// const General = () => (
//     <div className="billig-prilla general generalwhite">
//         <h2>Billigprilla</h2>
//         <small>- CLASSIC .SE –</small>
//     </div>
// );
// const Ettan = () => (
//     <div className="billig-prilla ettan" />
// );

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: '',
};

export default Header;
